.mainLogin {
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;
    width: 100%;
}

.mainLoginNuevo {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.backImg {
}
.image-container {
    width: 50%;
    height: 100vh; /* Define la altura visible */
    overflow: hidden; /* Oculta la parte sobrante */
    position: relative; /* Necesario para el control de la posición del contenido */
  }

  .image-container img {
    width: 100%;
    position: relative;
    top: -100px;
  }
.imgLogin {
    position: absolute;
    top: 30%;
    left: 66%;
    width: 20%;
    height: 10%;
}

.formLogin {
    position: absolute;
}

.userLogin {
    position: absolute;
    top: 50%;
    left: 64%;
    width: 26%;

}

.passwordLogin {
    position: absolute;
    top: 62%;
    left: 64%;
    width: 26%;
}

.userFalseLogin {
    position: absolute;
    top: 82%;
    left: 70%;
    width: 26%;
}

.submitLogin {
    position: absolute;
    top: 75%;
    left: 70%;
    width: 14%;
    height: 4%;
    background: #3161F1 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
    border-radius: 20px;
}

.btnSub {
    border: none;
    background: none;
}

.input {
    background: #DFE6F9 0% 0% no-repeat padding-box;
    box-shadow: 6px 3px 6px #00000029;
    border-radius: 36px;
    opacity: 0.47;
    height: 82px;
    border: none;
    padding-left: 50px;
}

.input::placeholder {
    color: #707070;
    font-size: 20px;
}










.loginHeaderCliente {
    display: flex;
    height: 8vh;
    width: 100%;
}

.mainLoginCliente {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.fotoMainClienteLadoIzqContenedor {
    width: 100%;
    height: 100vh
}

.fotoMainClienteLadoIzq {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap');

.tituloClienteLogin {
    font-family: 'Manrope', sans-serif;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
    margin-top: 60px;
    margin-bottom: 42px;
}

.inputPatenteClienteLogin {
    background: transparent linear-gradient(90deg, #F9B035 0%, #F98C4E 53%, #F96767 100%) 0% 0% no-repeat padding-box;
    box-shadow: 4px 23px 57px #F9676742;
    border-radius: 22px;
    border: none;
    width: 358px;
    height: 55px;
    color: #FFFFFF;
    text-align: center;
    font-family: 'Manrope', sans-serif;
    font-size: 27px;
    font-weight: 800;
    margin-right: 20px;
}

.inputFechaStroClienteLogin {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 22px;
    opacity: 1;
    width: 240px;
    height: 55px;
    border: none;
    text-align: center;
    color: #6149CD;
    font-family: 'Manrope', sans-serif;
    font-size: 21px;
    font-weight: 800;
}

.expedienteNroClienteLogin {
    color: #7A86A1;
    font-family: 'Manrope', sans-serif;
    font-size: 24px;
    font-weight: 400;
    margin-top: 85px;
    margin-bottom: 47px;
}

.expedienteEstadoClienteLogin {
    color: #7A86A1;
    font-family: 'Manrope', sans-serif;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 117px;
}

.labelInfoExpCliente {
    font-family: 'Manrope', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #000000;
    display: block;
    margin-right: 20px;
}

.inputInfoExpCliente {
    width: 299px;
    height: 50px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 20px;
    opacity: 1;
    margin-bottom: 20px;
    margin-right: 20px;
}

.inputInfoAdicionalExpCliente {
    width: 619px;
    height: 77px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 20px;
    opacity: 1;
    margin-bottom: 20px;
    margin-right: 20px;
}

.verMasInfoExpCliente {
    width: 174px;
    height: 55px;
    /* UI Properties */
    background: #6149CD 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F970D;
    border-radius: 22px;
    opacity: 1;
    color: #FFFFFF;
    border: none;
}



.ingreseSusDatosCliente {
    font-family: 'Manrope', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #20242D;
    margin-top: 10vh;
}

.labelLogin {
    font-family: 'Manrope', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #20242D;
    padding-top: 160px;
    display: block !important;
}

.labelLogin375 {
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #20242D;
    display: block !important;
}

.inputPatenteLogin {
    width: 250px;
    height: 6vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 22px;
    opacity: 1;
    border: none;
    margin-top: 1vh;
    font-family: 'Manrope', sans-serif;
    font-size: 18px;
    font-weight: 800;
    color: #3E63EF;
    text-align: center;
    outline: none;
}

.labelFechaStroLogin {
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    display: block;
    padding-top: 4vh;
}

.inputDateContainerLogin {
    position: relative;
    display: inline-block;
}

.inputDateLogin {
    width: 200px;
    height: 3vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 22px;
    opacity: 1;
    border: 1px solid black;
    margin-top: 2vh;
    font-family: 'Manrope', sans-serif;
    font-size: 26px;
    font-weight: 800;
    color: #3E63EF;
    text-align: center;
    outline: none;
    cursor: pointer;
}

.calendar-icon {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #5475F0;
    font-size: 26px;
}

.inputDateLogin::-webkit-calendar-picker-indicator {
    display: none;
}

.inputDateLogin::-moz-calendar-picker-indicator {
    display: none;
}

.submitLoginNuevo {
    width: 180px;
    height: 50px;
    background: transparent linear-gradient(90deg, #F9B035 0%, #F98C4E 53%, #F96767 100%) 0% 0% no-repeat padding-box;
    box-shadow: 4px 23px 57px #F9676742;
    border-radius: 32px;
    opacity: 1;
    font-family: 'Manrope', sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
    border: none;
    display: block;
    margin: auto;
    margin-top: 65px;
}

.submitLoginNuevo375 {
    width: 160px;
    height: 5vh;
    background: transparent linear-gradient(90deg, #F9B035 0%, #F98C4E 53%, #F96767 100%) 0% 0% no-repeat padding-box;
    box-shadow: 4px 23px 57px #F9676742;
    border-radius: 32px;
    opacity: 1;
    font-family: 'Manrope', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    border: none;
    display: block;
    margin: auto;
    margin-top: 5vh;
}