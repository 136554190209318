.estadoA {
    color: white;
    background-color: #BDBAD4;
    top: 160px;
    left: 1434px;
    width: 114px;
    height: 27px;
    padding: 5px;
    display: inline;
}

.estadoB {
    color: white;
    background-color: #001EA3;
    top: 160px;
    left: 1434px;
    width: 114px;
    height: 27px;
    padding: 5px;
    display: inline;
}

.estadoC {
    color: white;
    background-color: #1235D4;
    top: 160px;
    left: 1434px;
    width: 114px;
    height: 27px;
    padding: 5px;
    display: inline;
}

.estadoD {
    color: white;
    background-color: #126CDF;
    top: 160px;
    left: 1434px;
    width: 114px;
    height: 27px;
    padding: 5px;
    display: inline;
}

.estadoE {
    color: white;
    background-color: #1C95C7;
    top: 160px;
    left: 1434px;
    width: 114px;
    height: 27px;
    padding: 5px;
    display: inline;
}

.estadoF {
    color: white;
    background-color: #15E0B7;
    top: 160px;
    left: 1434px;
    width: 114px;
    height: 27px;
    padding: 5px;
    display: inline;
}

.estadoG {
    color: white;
    background-color: #1AFF66;
    top: 160px;
    left: 1434px;
    width: 114px;
    height: 27px;
    padding: 5px;
    display: inline;
}

.estadoH {
    color: white;
    background-color: orange;
    top: 160px;
    left: 1434px;
    width: 114px;
    height: 27px;
    padding: 5px;
    display: inline;
}

.estadoI {
    color: white;
    background-color: red;
    top: 160px;
    left: 1434px;
    width: 114px;
    height: 27px;
    padding: 5px;
    display: inline;
}



.estadoA1366 {
    color: white;
    background-color: #BDBAD4;
    top: 160px;
    left: 1434px;
    width: 80px;
    height: 20px;
    padding: 2px;
    display: inline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 20px;
    font-size: 12px;
}

.estadoB1366 {
    color: white;
    background-color: #001EA3;
    top: 160px;
    left: 1434px;
    width: 80px;
    height: 20px;
    padding: 2px;
    display: inline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 20px;
    font-size: 12px;
}

.estadoC1366 {
    color: white;
    background-color: #1235D4;
    top: 160px;
    left: 1434px;
    width: 80px;
    height: 20px;
    padding: 2px;
    display: inline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 20px;
    font-size: 12px;
}

.estadoD1366 {
    color: white;
    background-color: #126CDF;
    top: 160px;
    left: 1434px;
    width: 80px;
    height: 20px;
    padding: 2px;
    display: inline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 20px;
    font-size: 12px;
}

.estadoE1366 {
    color: white;
    background-color: #1C95C7;
    top: 160px;
    left: 1434px;
    width: 80px;
    height: 20px;
    padding: 2px;
    display: inline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 20px;
    font-size: 12px;
}

.estadoF1366 {
    color: white;
    background-color: #15E0B7;
    top: 160px;
    left: 1434px;
    width: 80px;
    height: 20px;
    padding: 2px;
    display: inline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 20px;
    font-size: 12px;
}

.estadoG1366 {
    color: white;
    background-color: #1AFF66;
    top: 160px;
    left: 1434px;
    width: 80px;
    height: 20px;
    padding: 2px;
    display: inline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 20px;
    font-size: 12px;
}

.estadoH1366 {
    color: white;
    background-color: orange;
    top: 160px;
    left: 1434px;
    width: 80px;
    height: 20px;
    padding: 2px;
    display: inline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 20px;
    font-size: 12px;
}

.estadoI1366 {
    color: white;
    background-color: red;
    top: 160px;
    left: 1434px;
    width: 80px;
    height: 20px;
    padding: 2px;
    display: inline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 20px;
    font-size: 12px;
}