#labelDoc {
    cursor: pointer;
    color: white;
}

#cedulaA {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#cedulaB {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#registroA {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#registroB {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#presupuesto {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#ocho {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#dniA {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#dniB {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#automotor {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#fotos {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#vtv {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#denuncia {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#lesionesDoc {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#lesionesFotos {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#cobertura {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#franquicia {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#docMedica {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#fotosLes {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#denunciaPolicial {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.btnDocumentacionNuevoExp {
    height: 40px;
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: 10px;
    border-radius: 20px;
    box-shadow: 3px 3px 3px #00000029;
    ;
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.infoExpContainerAnclado375 {
    margin-top: 3vh;
    width: 100%;
    min-height: 30vh;
    max-height: 45vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D5D5D5;
    border-radius: 16px;
}
.infoExpContainerAnclado412 {
    margin-top: 3vh;
    width: 100%;
    min-height: 30vh;
    max-height: 45vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D5D5D5;
    border-radius: 16px;
}
.infoExpContainerAnclado480 {
    margin-top: 3vh;
    width: 100%;
    min-height: 30vh;
    max-height: 45vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D5D5D5;
    border-radius: 16px;
}
.infoExpContainerAnclado768 {
    margin-top: 3vh;
    width: 100%;
    min-height: 35vh;
    max-height: 50vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D5D5D5;
    border-radius: 16px;
}
.botonActivoPlanillaExp {
    background: transparent linear-gradient(270deg, #F98D4E 0%, #F9AB39 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 20px;
    opacity: 1;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botonInactivoPlanillaExp {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botonActivoPlanillaExp375 {
    background: transparent linear-gradient(270deg, #F98D4E 0%, #F9AB39 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 30px;
    opacity: 1;
    width: 100%;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botonInactivoPlanillaExp375 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botonActivoPlanillaExp412 {
    background: transparent linear-gradient(270deg, #F98D4E 0%, #F9AB39 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 30px;
    opacity: 1;
    width: 100%;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botonInactivoPlanillaExp412 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.botonActivoPlanillaExp480 {
    background: transparent linear-gradient(270deg, #F98D4E 0%, #F9AB39 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 30px;
    opacity: 1;
    width: 100%;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botonInactivoPlanillaExp480 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.botonActivoPlanillaExp768 {
    background: transparent linear-gradient(270deg, #F98D4E 0%, #F9AB39 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 30px;
    opacity: 1;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botonInactivoPlanillaExp768 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btnCambioPlanilla {
    border: none;
    background: none;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 14px;
}

.btnCambioPlanillaInactivo {
    border: none;
    background: none;
    color: #F99946;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 14px;
}

.btnCambioPlanilla375 {
    border: none;
    background: none;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 9px;
}

.btnCambioPlanillaInactivo375 {
    border: none;
    background: none;
    color: #F99946;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 9px;
}

.btnCambioPlanilla412 {
    border: none;
    background: none;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 9px;
}

.btnCambioPlanillaInactivo412 {
    border: none;
    background: none;
    color: #F99946;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 9px;
}

.btnCambioPlanilla480 {
    border: none;
    background: none;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 9px;
}

.btnCambioPlanillaInactivo480 {
    border: none;
    background: none;
    color: #F99946;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 9px;
}

.btnCambioPlanilla768 {
    border: none;
    background: none;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 16px;
}

.btnCambioPlanillaInactivo768 {
    border: none;
    background: none;
    color: #F99946;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 16px;
}