@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

.empleado {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 5px 20px;
    min-height: 56px;
    width: 83%;
    margin-left: 15%;
    opacity: 1;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-top: 20px;
}

.gerencia {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px 20px 20px 5px;
    opacity: 1;
    min-height: 56px;
    width: 83%;
    margin-left: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-top: 20px;
}

.empleadoViejo {
    background: #5DA1F9 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 5px 20px;
    min-height: 56px;
    width: 83%;
    margin-left: 15%;
    opacity: 1;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-top: 20px;
}

.gerenciaViejo {
    background: #51638C 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px 20px 20px 5px;
    opacity: 1;
    min-height: 56px;
    width: 83%;
    margin-left: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-top: 20px;
}

.ancladoEmpleado {
    background: #FF873F 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 5px 20px;
    min-height: 56px;
    width: 83%;
    margin-left: 15%;
    color: white;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-top: 20px;
}

.ancladoGerencia {
    background: #FF873F 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 20px 5px;
    min-height: 56px;
    width: 83%;
    margin-left: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-top: 20px;
}

.privadoEmpleado {
    background: #0F2D81 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 5px 20px;
    min-height: 56px;
    width: 83%;
    margin-left: 15%;
    color: white;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-top: 20px;
}

.privadoGerencia {
    background: #0F2D81 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 20px 5px;
    min-height: 56px;
    width: 83%;
    margin-left: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-top: 20px;
}

.ofrecimientoGerencia {
    background: #3FFFB2 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 20px 5px;
    min-height: 56px;
    width: 83%;
    margin-left: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-top: 20px;
}

.ofrecimientoEmpleado {
    background: #3FFFB2 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 5px 20px;
    min-height: 56px;
    width: 83%;
    margin-left: 15%;
    color: white;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-top: 20px;
}

.mediacionGerencia {
    background-color: #F2AB6D;
    border-radius: 20px 20px 20px 5px;
    min-height: 56px;
    width: 83%;
    margin-left: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-top: 20px;
}

.mediacionEmpleado {
    background-color: #F2884C;
    border-radius: 20px 20px 5px 20px;
    min-height: 56px;
    width: 83%;
    margin-left: 15%;
    color: white;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-top: 20px;
}


.userTextDetalleEmpleado {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 15px;
    color: #656565;
    text-align: end;
    padding-right: 2%;
}

.mensajeDetalleEmpleado {
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 15px;
    font-size: 14px;
    color: #2E2E2E;
}

.userTextDetalleEmpleado2 {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 15px;
    color: #FFFFFF;
    text-align: end;
    padding-right: 2%;
}

.mensajeDetalleEmpleado2 {
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 15px;
    font-size: 14px;
    color: #FFFFFF;
}

.horaDetalleEmpleado {
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    font-style: italic;
    color: #777777;
    letter-spacing: 0;
    line-height: 11px;
    font-size: 9px;
    font-style: italic;
    padding-left: 60%;
    padding-top: 0.5%;
}



.userTextDetalleGerencia {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
    color: #656565;
    padding-left: 3%;
}

.mensajeDetalleGerencia {
    padding-left: 10px;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 17px;
    font-size: 14px;
    color: #090909;
    text-indent: 12%;
}

.userTextDetalleGerencia2 {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
    color: #FFFFFF;
    padding-left: 3%;
}

.mensajeDetalleGerencia2 {
    padding-left: 10px;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 17px;
    font-size: 14px;
    color: #FFFFFF;
    text-indent: 12%;
}

.horaDetalleGerencia {
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    font-style: italic;
    color: #777777;
    letter-spacing: 0;
    line-height: 11px;
    font-size: 9px;
    font-style: italic;
    padding-left: 3%;
    padding-top: 0.5%;
}

.user {
    font-size: 15px;
}

.privadoBtn {
    background: #0F2D81 0% 0% no-repeat padding-box;
    opacity: 1;
}

.privadoBtnMovil {
    background: #0F2D81 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 19px;
    font-size: 10px;
}

.ofrecimientoBtn {
    background: #3FFFB2 0% 0% no-repeat padding-box;
    border-radius: 19px;
    opacity: 1;
}

.ofrecimientoBtnMovil {
    background: #3FFFB2 0% 0% no-repeat padding-box;
    border-radius: 19px;
    opacity: 1;
    font-size: 10px;
}

.ofrecimientoBtnMovil {
    background: #3FFFB2 0% 0% no-repeat padding-box;
    border-radius: 19px;
    opacity: 1;
    font-size: 10px;
}

.clavadoBtn {
    background: #FF873F 0% 0% no-repeat padding-box;
}

.clavadoBtnMovil {
    background: #FF873F 0% 0% no-repeat padding-box;
    border-radius: 19px;
    opacity: 1;
    font-size: 10px;
}

.detallesContainer {
    height: 560px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0019BB38;
    border-radius: 25px;
}

.detallesContainerAnclado {
    position: absolute;
    top: 190px;
    left: 52%;
    width: 44%;
    height: 560px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0019BB38;
    border-radius: 25px;
}

.ofreLabel {
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0px;
    color: #3C1EFE;
    opacity: 0.37;
}

.ofreLabelMovil {
    text-align: left;
    font-size: 10px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0px;
    color: #3C1EFE;
    opacity: 0.37;
}

.privLabel {
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.privLabelMovil {
    text-align: left;
    font-size: 10px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.claLabel {
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.claLabelMovil {
    text-align: left;
    font-size: 10px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.mensajesContenedorConScroll {
    height: 420px;
    overflow-y: auto;
    margin-top: 15px;
}



.offCanvas {
    height: 100vh;
    width: 90vw;
    position: fixed;
    background-color: white;
    top: 0;
    right: 0;
    overflow-y: scroll;
    padding-bottom: 50px;
}




.detallesContainer375 {
    height: 80vh;
}

.mensajesContenedorConScroll375 {
    height: 60vh;
    margin-top: 1vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.mensajesContenedorConScroll375Pas {
    height: 80vh;
    margin-top: 1vh;
    overflow-y: auto;
    overflow-x: hidden;
}


.burbujaMensaje {
    height: 53px;
    width: 100%;
    border: 1px solid #5D5D5D38;
    border-radius: 27px;
    outline: none;
    opacity: 1;
    padding-left: 20px;
    margin-top: 10px;
}

.privadoSinSelect {
    height: 25px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
    text-align: left;
    font: normal normal bold 13px/20px Poppins;
    letter-spacing: 0px;
    color: #0043FF;
    opacity: 1;
    border-radius: 25px;
}

.clavadoSinSelect {
    height: 25px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
    text-align: left;
    font: normal normal bold 13px/20px Poppins;
    letter-spacing: 0px;
    color: #FF873F;
    opacity: 1;
    border-radius: 25px;
}

.ofrecimientoSinSelect {
    height: 25px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
    text-align: left;
    font: normal normal bold 12px/18px Poppins;
    letter-spacing: 0px;
    color: #47BC82;
    opacity: 1;
    border-radius: 25px;
}

.privadoSelect {
    height: 25px;
    background: #0F2D81 0% 0% no-repeat padding-box;
    border: none;
    opacity: 1;
    text-align: left;
    font: normal normal bold 13px/20px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    border-radius: 25px;
}

.clavadoSelect {
    height: 25px;
    background: #FF873F 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 19px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 13px/20px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    border-radius: 25px;
}

.ofrecimientoSelect {
    height: 25px;
    background: #47BC82 0% 0% no-repeat padding-box;
    border: none;
    opacity: 1;
    text-align: left;
    font: normal normal bold 12px/18px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    border-radius: 25px;
}

.btnEnviarSubmit {
    border: none;
    background: transparent;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    letter-spacing: 0px;
    line-height: 15px;
    font-size: 18px;
    color: #00000029;
}

.selectMsjAutomaticos {
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    letter-spacing: 0px;
    line-height: 15px;
    font-size: 13px;
    color: #000000;
    width: 200px;
    height: 25px;
    overflow-y: auto; 
}

.selectMsjAutomaticos:focus {
    outline: none;
}

.selectMsjAutomaticos option {
    font-family: 'Poppins', sans-serif;
    font-size: 9px;
    color: #707070;
}


@media (max-width: 400px) {

    /* Smartphones */
    .userTextDetalleEmpleado {
        text-align: left;
        font-size: 10px;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0px;
        color: #F9F6FF;
        padding-left: 85%;
        padding-top: 5px;
    }

    .mensajeDetalleEmpleado {
        text-align: left;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0px;
        color: #FAFDFF;
        padding-left: 10px;
    }

    .horaDetalleEmpleado {
        text-align: left;
        font-size: 9px;
        font-style: normal;
        font-weight: 100;
        letter-spacing: 0px;
        color: #777777;
        padding-left: 10%;
    }

    .userTextDetalleGerencia {
        text-align: left;
        font-size: 10px;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0px;
        color: #F9F6FF;
        padding-left: 3%;
        padding-top: 5px;
    }

    .mensajeDetalleGerencia {
        text-align: left;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0px;
        color: #FAFDFF;
        padding-left: 10px;
    }

    .horaDetalleGerencia {
        text-align: left;
        font-size: 9px;
        font-style: normal;
        font-weight: 100;
        letter-spacing: 0px;
        color: #777777;
        padding-left: 4%;
    }

    .empleado {
        background: #5DA1F9 0% 0% no-repeat padding-box;
        border-radius: 20px 20px 5px 20px;
        width: 90%;
        margin-left: 5%;
        color: white;
    }

    .gerencia {
        background: #51638C 0% 0% no-repeat padding-box;
        border-radius: 20px 20px 20px 5px;
        width: 95%;
        margin-left: 2%;
    }

    .ancladoEmpleado {
        background: #FF873F 0% 0% no-repeat padding-box;
        border-radius: 20px 20px 5px 20px;
        width: 90%;
        margin-left: 5%;
        color: white;
    }

    .ancladoGerencia {
        background: #FF873F 0% 0% no-repeat padding-box;
        border-radius: 20px 20px 20px 5px;
        width: 95%;
        margin-left: 2%;
    }

    .privadoEmpleado {
        background: #0F2D81 0% 0% no-repeat padding-box;
        border-radius: 20px 20px 5px 20px;
        width: 90%;
        margin-left: 5%;
        color: white;
    }

    .privadoGerencia {
        background: #0F2D81 0% 0% no-repeat padding-box;
        border-radius: 20px 20px 20px 5px;
        width: 95%;
        margin-left: 2%;
    }

    .ofrecimientoGerencia {
        background: #3FFFB2 0% 0% no-repeat padding-box;
        border-radius: 20px 20px 20px 5px;
        width: 95%;
        margin-left: 2%;
    }

    .ofrecimientoEmpleado {
        background: #3FFFB2 0% 0% no-repeat padding-box;
        border-radius: 20px 20px 5px 20px;
        width: 90%;
        margin-left: 5%;
        color: white;
    }

    .mediacionGerencia {
        background-color: #F2AB6D;
        border-radius: 20px 20px 20px 5px;
        width: 95%;
        margin-left: 2%;
    }

    .mediacionEmpleado {
        background-color: #F2884C;
        border-radius: 20px 20px 5px 20px;
        width: 90%;
        margin-left: 5%;
        color: white;
    }
}