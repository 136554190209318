.ancladoContainer {
    height: 76px;
    background: #FF8F3A 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 9px;
    opacity: 1;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px
}



@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400&display=swap');

.textoMensajeAnclado {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-size: 13px;
    font-weight: 400;
    color: #FFFFFF;
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}






.ancladoContainer375 {
    background: #FF8F3A 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    padding-left: 11px;
    padding-right: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ancladoContainer375Blanco {
    background: transparent;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    padding-left: 11px;
    padding-right: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.textoMensajeAnclado375 {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}
.ancladoContainer768 {
    background: #FF8F3A 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    padding-left: 11px;
    padding-right: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.textoMensajeAnclado768 {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.ancladoContainer412 {
    background: #FF8F3A 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    padding-left: 11px;
    padding-right: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.textoMensajeAnclado412 {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-size: 10px;
    font-weight: 400;
    color: #FFFFFF;
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}

.ancladoContainer480 {
    background: #FF8F3A 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 9px;
    opacity: 1;
    width: 100%;
    padding-left: 11px;
    padding-right: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.textoMensajeAnclado480 {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-size: 10px;
    font-weight: 400;
    color: #FFFFFF;
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}