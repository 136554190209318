.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.estadoA {
    color: white; 
    background-color: #BDBAD4;
    font-size: 14px; 
    border-radius: 19px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0px;  
    text-align: center;
}
.estadoB {
    color: white; 
    background-color: #001EA3;
    font-size: 14px; 
    border-radius: 19px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0px;  
    text-align: center;
}
.estadoC {
    color: white; 
    background-color: #1235D4;
    font-size: 14px; 
    border-radius: 19px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0px;  
    text-align: center;
}
.estadoD {
    color: white; 
    background-color: #126CDF;
    font-size: 14px; 
    border-radius: 19px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0px;  
    text-align: center;
}
.estadoE {
    color: white; 
    background-color: #1C95C7;
    font-size: 14px; 
    border-radius: 19px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0px;  
    text-align: center;
}
.estadoF {
    color: white; 
    background-color: #15E0B7;
    font-size: 14px; 
    border-radius: 19px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0px;  
    text-align: center;
}
.estadoG {
    color: white; 
    background-color: #1AFF66;
    font-size: 14px; 
    border-radius: 19px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0px;  
    text-align: center; 
}
.estadoH {
    color: white; 
    background-color: orange;
    font-size: 14px; 
    border-radius: 19px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0px;  
    text-align: center;
}
.estadoI {
    color: white; 
    background-color: red;
    font-size: 14px; 
    border-radius: 19px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0px;  
    text-align: center;
}