@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
.infoExpContainerCliente {
    max-height: 484px;
    width: 645px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D5D5D5;
    border-radius: 28px;
    opacity: 1;
    margin: auto;
    margin-top: 2vh;
}
.infoExpContainerCliente375 {
    width: 100%;
    max-height: 267px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D5D5D5;
    border-radius: 16px;
}
.infoExpContainerCliente412 {
    width: 100%;
    max-height: 294px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D5D5D5;
    border-radius: 16px;
}
.infoExpContainerCliente480 {
    width: 100%;
    max-height: 320px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D5D5D5;
    border-radius: 16px;
}
.botonActivoPlanillaExpCliente {
    background: transparent linear-gradient(270deg, #F98D4E 0%, #F9AB39 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 20px;
    opacity: 1;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botonInactivoPlanillaExpCliente {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botonActivoPlanillaExpCliente375 {
    background: transparent linear-gradient(270deg, #F98D4E 0%, #F9AB39 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 30px;
    opacity: 1;
    width: 100%;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botonInactivoPlanillaExpCliente375 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botonActivoPlanillaExpCliente412 {
    background: transparent linear-gradient(270deg, #F98D4E 0%, #F9AB39 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 30px;
    opacity: 1;
    width: 100%;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botonInactivoPlanillaExpCliente412 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.botonActivoPlanillaExpCliente480 {
    background: transparent linear-gradient(270deg, #F98D4E 0%, #F9AB39 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 30px;
    opacity: 1;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botonInactivoPlanillaExpCliente480 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btnCambioPlanillaCliente {
    border: none;
    background: none;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 14px;
}

.btnCambioPlanillaInactivoCliente {
    border: none;
    background: none;
    color: #F99946;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 14px;
}

.btnCambioPlanillaCliente375 {
    border: none;
    background: none;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 9px;
}

.btnCambioPlanillaInactivoCliente375 {
    border: none;
    background: none;
    color: #F99946;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 9px;
}

.btnCambioPlanillaCliente412 {
    border: none;
    background: none;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 12px;
}

.btnCambioPlanillaInactivoCliente412 {
    border: none;
    background: none;
    color: #F99946;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 12px;
}

.btnCambioPlanillaCliente480 {
    border: none;
    background: none;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 14px;
}

.btnCambioPlanillaInactivoCliente480 {
    border: none;
    background: none;
    color: #F99946;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 14px;
}