.tablaHead {
    background: #0089FF 0% 0% no-repeat padding-box;
    border-radius: 16px;
    height: 30px;
    padding: 0 10px;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(9, 1fr); /* Divide en 9 columnas iguales */
    align-items: center; /* Centrar contenido verticalmente */
    gap: 10px;
}
.tablaHeadCobros {
    background: #0089FF 0% 0% no-repeat padding-box;
    border-radius: 16px;
    height: 30px;
    padding: 0 10px;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(10, 1fr); /* Divide en 9 columnas iguales */
    align-items: center; /* Centrar contenido verticalmente */
    gap: 10px;
}
.tablaHeadComp {
    background: #0089FF 0% 0% no-repeat padding-box;
    border-radius: 16px;
    height: 50px;
    align-items: center;
    padding: 0;
    margin: 0;
}
.tablaHeadPas {
    background: #0089FF 0% 0% no-repeat padding-box;
    border-radius: 16px;
    height: 30px;
    padding: 0 10px;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(8, 1fr); /* Divide en 9 columnas iguales */
    align-items: center; /* Centrar contenido verticalmente */
    gap: 10px;
}
.tituloTabla {
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin: 0;
}
.tituloTablaBigScreen {
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin: 0;
}
.titulo2Tabla {
    font-size: 10px;
    font-weight: 900;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin: 0;
    text-align: center;
}
.tituloTabla1366 {
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin: 0;
}
.titulo2Tabla1366 {
    font-size: 10px;
    font-weight: 900;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin: 0;
    text-align: center;
}
.tituloTabla2 {
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin: 0;
}
.rmsc {
    height: 44px;
    --rmsc-h: 44px;
    --rmsc-border: none;
    --rmsc-main: transparent;
}

.dropdown-heading {
    background: #F2F4F6 0% 0% no-repeat padding-box;
    border-radius: 11px;
    color: #555555;
    font-size: 14px;
}

.subtituloDelFiltro {
    color: #9DA9C4;
    font-size: 14px;
}

.inputsDelFiltro {
    width: 100%;
    height: 44px;
    background: #F2F4F6 0% 0% no-repeat padding-box;
    border-radius: 11px;
    border: none;
    padding-left: 18px;
    color: #555555;
    font-size: 14px;
}
.css-b62m3t-container::after {
    width: 100%;
    height: 44px;
    background: #F2F4F6 0% 0% no-repeat padding-box;
    border-radius: 11px;
    border: none;
    padding-left: 18px;
    color: #555555;
    font-size: 14px;
    padding: 0;
}
.react-select-container {
    width: 100%;
    height: 44px;
    background: #F2F4F6 0% 0% no-repeat padding-box;
    border-radius: 11px;
    border: none;
    padding-left: 18px;
    color: #555555;
    font-size: 14px;
    padding: 0;
}
.css-13cymwt-control::before {
    width: 100%;
    height: 44px;
    background-color: transparent;
    border: none;
}
.inputsDelFiltroSearch {
    width: 100%;
    height: 44px;
    background: #F2F4F6 0% 0% no-repeat padding-box;
    border-radius: 11px;
    border: none;
    padding-left: 18px;
    color: #555555;
    font-size: 14px;
}

.subtitleFiltro {
    color: #9DA9C4;
    font-size: 14px;
    font-weight: 400;
}

