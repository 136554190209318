@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,600;1,600&display=swap');

.miniExpContainerMediacion375 {
    height: 30px;
    background: transparent linear-gradient(82deg, #F9B035 0%, #F98C4E 53%, #F96767 100%) 0% 0% no-repeat padding-box;
    border-radius: 28px;
    opacity: 1;
    padding-left: 17.5px;
    color: #FFFFFF;
    max-width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-style: italic;
    letter-spacing: 0;
    line-height: 30px;
}
.miniExpContainerMediacion768 {
    height: 40px;
    background: transparent linear-gradient(82deg, #F9B035 0%, #F98C4E 53%, #F96767 100%) 0% 0% no-repeat padding-box;
    border-radius: 28px;
    opacity: 1;
    padding-left: 17.5px;
    color: #FFFFFF;
    max-width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-style: italic;
    letter-spacing: 0;
    line-height: 40px;
}

.botonVerChat75 {
    width: 38px;
    height: 100%;
    background: #F9765D 0% 0% no-repeat padding-box;
    box-shadow: 2px 14px 32px #6149CD1A;
    border-radius: 19px;
    opacity: 1;
    border: none;
    color: #FFFFFF;
    font-size: 13px;
}   
.botonVerChat768 {
    width: 40px;
    height: 100%;
    background: #F9765D 0% 0% no-repeat padding-box;
    box-shadow: 2px 14px 32px #6149CD1A;
    border-radius: 19px;
    opacity: 1;
    border: none;
    color: #FFFFFF;
    font-size: 13px;
}   




@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
.textMiniTablaMediacion375 {
    font-size: 12px;
    color: #000000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-style: normal;
    padding-left: 7px;
}

.textMiniTablaMediacion412 {
    font-size: 12px;
    color: #000000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-style: normal;
    padding-left: 7px;
}

.textMiniTablaMediacion768 {
    font-size: 16px;
    color: #000000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-style: normal;
    padding-left: 12px;
}