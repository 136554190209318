.acordeonContainer {
    height: 600px;
}

.headerPresentAcordeonNaranja {
    width: 100%;
    height: 47px;
    background: orange;
    border-radius: 26px;
    border: none;
}

.headerPresentAcordeonNaranja:not(.collapsed) {
    border-radius: 26px 26px 0px 0px;
    border-left: 1px solid #0019BB38;
    border-right: 1px solid #0019BB38;
    border-top: 1px solid #0019BB38;
    border-bottom: none;
}

.headerSuccessAcordeon {
    width: 100%;
    height: 47px;
    background: #D6FAEC 0% 0% no-repeat padding-box;
    border-radius: 26px;
    border: none;
}

.headerSuccessAcordeon:not(.collapsed) {
    border-radius: 26px 26px 0px 0px;
    border-left: 1px solid #0019BB38;
    border-right: 1px solid #0019BB38;
    border-top: 1px solid #0019BB38;
    border-bottom: none;
}

.headerPresentAcordeon {
    width: 100%;
    height: 47px;
    background: #E9F3FF8C 0% 0% no-repeat padding-box;
    border-radius: 26px 26px;
    border: none;
}

.headerPresentAcordeon:not(.collapsed) {
    border-radius: 26px 26px 0px 0px;
    border-left: 1px solid #0019BB38;
    border-right: 1px solid #0019BB38;
    border-top: 1px solid #0019BB38;
    border-bottom: none;
}

.headerFutureAcordeon {
    width: 100%;
    height: 47px;
    background: #F3F8FF 0% 0% no-repeat padding-box;
    border-radius: 26px 26px;
    border: none;
}

.headerFutureAcordeon:not(.collapsed) {
    border-radius: 26px 26px 0px 0px;
    border-left: 1px solid #0019BB38;
    border-right: 1px solid #0019BB38;
    border-top: 1px solid #0019BB38;
    border-bottom: none;
}

.bodyAcordeon {
    width: 100%;
    height: 280px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 26px 26px;
    border-left: 1px solid #0019BB38;
    border-right: 1px solid #0019BB38;
    border-bottom: 1px solid #0019BB38;
    margin-top: 0;
    opacity: 1;
}

.bodyAcordeon2 {
    width: 100%;
    height: 420px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 26px 26px;
    border-left: 1px solid #0019BB38;
    border-right: 1px solid #0019BB38;
    border-bottom: 1px solid #0019BB38;
    margin-top: 0;
    opacity: 1;
}

.tituloAcordeon {
    font-size: 15px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.estadoCont {
    height: 28px;
    width: 100px;
    font-size: 10px;
    border-radius: 10px;
    text-overflow: ellipsis;
    color: white;
    text-align: center;
}

.estadoAMediacion {
    background-color: #BDBAD4;
}

.estadoBMediacion {
    background-color: #001EA3;
}

.estadoCMediacion {
    background-color: #1235D4;
}

.estadoDMediacion {
    background-color: #126CDF;
}

.estadoEMediacion {
    background-color: #1C95C7;
}

.estadoFMediacion {
    background-color: #15E0B7;
}

.estadoGMediacion {
    background-color: #1AFF66;
}

.estadoHMediacion {
    background-color: orange;
}

.estadoIMediacion {
    background-color: red;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,600;1,600&display=swap');

.miniExpContainerMediacion {
    height: 51px;
    background: transparent linear-gradient(86deg, #F9B035 0%, #F98C4E 53%, #F96767 100%) 0% 0% no-repeat padding-box;
    border-radius: 28px;
    opacity: 1;
    padding-left: 17.5px;
    color: #FFFFFF;
    max-width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-style: italic;
    letter-spacing: 0;
    line-height: 51px;
}

.tituloMediacion {
    font-size: 18px;
    line-height: 22px;
    font-weight: bolder;
    letter-spacing: 0px;
    color: #0722A8;
    opacity: 1;
}

.tituloMediacion1366 {
    font-size: 14px;
    line-height: 22px;
    font-weight: bolder;
    letter-spacing: 0px;
    color: #0722A8;
    opacity: 1;
}

.textMiniTablaMediacion {
    font-size: 13px;
    color: #000000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-style: normal;
}

.textMiniTablaMediacion1366 {
    font-size: 14px;
    line-height: 22px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0722A8;
    opacity: 1;
    word-wrap: break-word;
    word-break: break-all;
    text-overflow: ellipsis;
}

.textMiniTablaMediacion1366 {
    font-size: 14px;
    line-height: 22px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0722A8;
    opacity: 1;
    word-wrap: break-word;
    word-break: break-all;
    text-overflow: ellipsis;
}

.textMiniTablaMediacion1366 {
    font-size: 14px;
    line-height: 22px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0722A8;
    opacity: 1;
    word-wrap: break-word;
    word-break: break-all;
    text-overflow: ellipsis;
}

.subTextMiniTablaMediacion {
    font-size: 14px;
    font-weight: bold;
    color: #0722A8;
}

.subTextMiniTablaMediacion1366 {
    font-size: 12px;
    font-weight: bold;
    color: #0722A8;
}




.contenedorPlanillaMediacion {
    margin-bottom: 5px;
}


.estadoExpA {
    color: white;
    background-color: #BDBAD4;
}

.estadoExpB {
    color: white;
    background-color: #001EA3;
}

.estadoExpC {
    color: white;
    background-color: #1235D4;
}

.estadoExpD {
    color: white;
    background-color: #126CDF;
}

.estadoExpE {
    color: white;
    background-color: #1C95C7;
}

.estadoExpF {
    color: white;
    background-color: #15E0B7;
}

.estadoExpG {
    color: white;
    background-color: #1AFF66;
}

.estadoExpH {
    color: white;
    background-color: orange;
}

.estadoExpH2 {
    color: white;
    background-color: orange;
}

.estadoExpI {
    color: white;
    background-color: red;
}

.estadoExp375 {
    width: 74px;
    height: 18px;
    border-radius: 19px;
    font-size: 7px;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-style: normal;
    text-align: center;
}