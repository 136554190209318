.mainLoginCliente {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.mainLoginClienteMovil {
    width: 100%;
    margin: auto;
    padding-left: 3%;
    padding-right: 3%
}
.fotoMainClienteLadoIzqContenedor {
    width: 100%;
    height: 100vh
}

.fotoMainClienteLadoIzq {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap');
.expNroClienteMovilTexto {
    font-family: 'Manrope', sans-serif;
    color:  #7A86A1;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.expNroClienteMovilTexto375 {
    font-family: 'Manrope', sans-serif;
    color:  #7A86A1;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.expNroCliente {
    max-width: 262px;
    min-width: 100px;
    height: 61px;
    background: transparent linear-gradient(80deg, #F9B035 0%, #F98C4E 53%, #F96767 100%) 0% 0% no-repeat padding-box;
    border-radius: 28px;
    opacity: 1;
    font-family: 'Manrope', sans-serif;
    color: #FFFFFF;
    font-size: 23px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 140px;
}
.expNroClienteMovil {
    max-width: 262px;
    min-width: 100px;
    width: fit-content;
    height: 6vh;
    background: transparent linear-gradient(80deg, #F9B035 0%, #F98C4E 53%, #F96767 100%) 0% 0% no-repeat padding-box;
    border-radius: 28px;
    opacity: 1;
    font-family: 'Manrope', sans-serif;
    color: #FFFFFF;
    font-size: 23px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.subtituloTituloExpCliente {
    color: #7A86A1;
    font-size: 15px;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
}
.subtituloTituloExpCliente375 {
    color: #7A86A1;
    font-size: 12px;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
}

.elementosTituloExpCliente {
    margin-right: 50px;
}

.infoFechasClienteContenedor {
    width: 524px;
    margin: auto;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
}
.infoFechasClienteContenedorMovil {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5vh;
}


.estadoCliente {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    color: #7A86A1;
    font-size: 29px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
}

.estadoClienteMovil {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #F98C4E;
    font-size: 18px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
}
.estadoClienteMovil375 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #F98C4E;
    font-size: 14px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
}
.textoExplicacionCliente {
    width: 645px;
    height: 94px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 9px;
    opacity: 1;
    margin: auto;
    margin-top: 25px;
    color: #7A86A1;
    font-size: 13px;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}
.textoExplicacionClienteMovil {
    width: 100%;
    min-height: 10vh;
    max-height: 15vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 9px;
    opacity: 1;
    margin: auto;
    color: #7A86A1;
    font-size: 12px;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.textoExplicacionCliente {
    width: 60%;
    min-height: 10vh;
    max-height: 15vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 9px;
    opacity: 1;
    margin: auto;
    color: #7A86A1;
    font-size: 12px;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}