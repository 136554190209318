.estadoATabla {
    color: white;
    background-color: #BDBAD4;
    font-size: 12px;
    font-weight: bold;
    border-radius: 25px;
    height: 25px;
    width: 100%;
    padding: 10px;
}

.estadoBTabla {
    color: white;
    background-color: #001EA3;
    font-size: 12px;
    font-weight: bold;
    border-radius: 25px;
    height: 25px;
    width: 100%;
    padding: 10px;
}

.estadoCTabla {
    color: white;
    background-color: #1235D4;
    font-size: 9px;
    font-weight: bold;
    border-radius: 25px;
    height: 25px;
    width: 100%;
    padding: 10px;
}

.estadoDTabla {
    color: white;
    background-color: #126CDF;
    font-size: 9px;
    font-weight: bold;
    border-radius: 25px;
    height: 25px;
    width: 100%;
    padding: 10px;
}

.estadoETabla {
    color: white;
    background-color: #1C95C7;
    font-size: 12px;
    font-weight: bold;
    border-radius: 25px;
    height: 25px;
    width: 100%;
    padding: 10px;
}

.estadoFTabla {
    color: white;
    background-color: #15E0B7;
    font-size: 9px;
    font-weight: bold;
    border-radius: 25px;
    height: 25px;
    width: 100%;
    padding: 10px;
}

.estadoGTabla {
    color: white;
    background-color: #1AFF66;
    font-size: 14px;
    font-weight: bold;
    border-radius: 25px;
    height: 25px;
    width: 100%;
    padding: 10px;
}

.estadoHTabla {
    color: white;
    background-color: orange;
    font-size: 14px;
    font-weight: bold;
    border-radius: 25px;
    height: 25px;
    width: 100%;
    padding: 10px;
}
.estadoH2Tabla {
    color: white;
    background-color: orange;
    font-size: 11px;
    font-weight: bold;
    border-radius: 25px;
    height: 25px;
    width: 100%;
    padding: 10px;
}
.estadoITabla {
    color: white;
    background-color: red;
    font-size: 14px;
    font-weight: bold;
    border-radius: 25px;
    height: 25px;
    width: 100%;
    padding: 10px;
}


.containerTablaExpedientes {
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000F;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.tituloExpedienteTabla1 {
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 0px;
    color: #0722A8;
    align-self: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tituloExpedienteTabla11366 {
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 0px;
    color: #0722A8;
    align-self: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tituloExpedienteTabla2 {
    font-size: 17px;
    font-weight: 500;
    font-style: italic;
    letter-spacing: 0px;
    color: #0722A8;
    align-self: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tituloExpedienteTabla21366 {
    font-size: 12px;
    font-weight: 500;
    font-style: italic;
    letter-spacing: 0px;
    color: #0722A8;
    align-self: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tituloExpedienteTabla3 {
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 0px;
    color: #0722A8;
    align-self: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tituloExpedienteTabla31366 {
    font-size: 12px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 0px;
    color: #0722A8;
    align-self: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tituloExpedienteTabla4 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0722A8;
    align-self: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tituloExpedienteTabla41366 {
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0722A8;
    align-self: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tituloExpedienteTabla5 {
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 0px;
    color: #0722A8;
    align-self: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tituloExpedienteTabla51366 {
    font-size: 10px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 0px;
    color: #0722A8;
    align-self: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tituloExpedienteTabla6 {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    letter-spacing: 0px;
    color: #0722A8;
    align-self: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tituloExpedienteTabla61366 {
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    letter-spacing: 0px;
    color: #0722A8;
    align-self: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tituloExpedienteTabla7 {
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 0px;
    color: #8F90EF;
    align-self: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tituloExpedienteTabla71366 {
    font-size: 10px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 0px;
    color: #8F90EF;
    align-self: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tituloExpedienteTabla8 {
    align-self: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tituloExpedienteTabla9 {
    align-self: center;
}










.estadoATabla1366 {
    color: white;
    background-color: #BDBAD4;
    font-size: 10px;
    font-weight: bold;
    border-radius: 20px;
    height: 20px;
    width: 100%;
    text-align: center;
    padding: 10px;
}

.estadoBTabla1366 {
    color: white;
    background-color: #001EA3;
    font-size: 10px;
    font-weight: bold;
    border-radius: 20px;
    height: 20px;
    width: 100%;
    text-align: center;
    padding: 10px;
}

.estadoCTabla1366 {
    color: white;
    background-color: #1235D4;
    font-size: 10px;
    font-weight: bold;
    border-radius: 20px;
    height: 20px;
    width: 100%;
    text-align: center;
    padding: 10px;
}

.estadoDTabla1366 {
    color: white;
    background-color: #126CDF;
    font-size: 10px;
    font-weight: bold;
    border-radius: 20px;
    height: 20px;
    width: 100%;
    text-overflow: ellipsis;
    white-space:nowrap;
    text-align: center;
    padding: 10px;
}

.estadoETabla1366 {
    color: white;
    background-color: #1C95C7;
    font-size: 10px;
    font-weight: bold;
    border-radius: 20px;
    height: 20px;
    width: 100%;
    text-align: center;
    padding: 10px;
}

.estadoFTabla1366 {
    color: white;
    background-color: #15E0B7;
    font-size: 10px;
    font-weight: bold;
    border-radius: 20px;
    height: 20px;
    width: 100%;
    text-align: center;
    padding: 10px;
}

.estadoGTabla1366 {
    color: white;
    background-color: #1AFF66;
    font-size: 10px;
    font-weight: bold;
    border-radius: 20px;
    height: 20px;
    width: 100%;
    text-align: center;
    padding: 10px;
}

.estadoHTabla1366 {
    color: white;
    background-color: orange;
    font-size: 10px;
    font-weight: bold;
    border-radius: 20px;
    height: 20px;
    width: 100%;
    text-align: center;
    padding: 10px;
}
.estadoH2Tabla1366 {
    color: white;
    background-color: orange;
    font-size: 10px;
    font-weight: bold;
    border-radius: 20px;
    height: 20px;
    width: 100%;
    text-align: center;
    padding: 10px;
}
.estadoITabla1366 {
    color: white;
    background-color: red;
    font-size: 10px;
    font-weight: bold;
    border-radius: 20px;
    height: 20px;
    width: 100%;
    text-align: center;
    padding: 10px;
}