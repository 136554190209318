.filtroTicketContainer {
    height: 956px;
    box-shadow: -3px 3px 6px #0000001A;
    border-radius: 22px 0px 0px 22px;
}

.sectionExpedientes {
    padding-left: 2%;
    padding-top: 100px;
}

.sectionExpedientes2 {
    padding-left: 2%;
}

.mainExp {
    padding-top: 0px;
}

.mainCrearExp {
    padding-top: 120px;
}

.containerTitle {
    font-size: 20px;
    background-color: #0722A8;
    padding: 10px;
    border-radius: 20px;
}

.headerDeMediacion {
    padding-top: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 2%;
    padding-right: 2%;
}

.espacioHeaderExpediente {
    margin-right: 10px;
}

.espacioHeaderExpediente2 {
    margin-right: 50px;
}

.cambiarMediacionExpediente {
    position: absolute;
    left: 15vw;
    width: 10%;
    padding-top: 100px;
}


.flechaText {
    font-size: 10px;
}

.tituloAzul {
    position: absolute;
    left: 3vw;
    width: 25%;
    height: 60px;
    background: #0772FF 0% 0% no-repeat padding-box;
    box-shadow: 5px 3px 6px #00000029;
    opacity: 1;
    border-radius: 15px;
    z-index: 2;
}

.comp {
    text-align: left;
    font-size: 17px;
    font-style: normal;
    letter-spacing: 0px;
    color: #FAFDFF;
}

.ordenarPorInputTabla {
    position: absolute;
    margin-left: 72%;
    width: 200px;
    top: 95px;
}


.mainAjustes {
    padding-top: 120px;
}


.mainExpediente {
    padding-left: 2%;
    padding-right: 2%;
}

.mainExpedientePrueba {
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 80px;
}

.gridConDosColumnas {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 30px;
    width: 100%;
}

.gridConDosColumnasError {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 30px;
    width: 100%;
}








.custom-switch {
    display: inline-block;
    position: relative;
}

.custom-switch input {
    display: none;
    /* Ocultar el input checkbox */
}

.switch-label {
    display: flex;
    align-items: center;
    width: 60px;
    /* El ancho del switch */
    height: 30px;
    /* Altura del switch */
    background-color: blue;
    /* Fondo rojo cuando está apagado */
    border-radius: 30px;
    /* Redondear completamente */
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.switch-label .switch-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 30px;
    background-color: blue;
    /* Fondo rojo cuando está apagado */
    transition: background-color 0.3s ease;
}

.switch-label .switch-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    /* Un poco más pequeño que el height del switch */
    height: 26px;
    /* Altura igual al ancho del círculo */
    background-color: white;
    border-radius: 50%;
    /* Círculo perfecto */
    position: absolute;
    top: 2px;
    /* Para centrar dentro del switch */
    left: 2px;
    /* Espacio inicial para el círculo */
    color: black;
    /* Color del texto */
    font-size: 10px;
    /* Tamaño del texto */
    transition: all 0.3s ease;
}

/* Estilos cuando el switch está encendido */
input:checked+.switch-label .switch-background {
    background-color: orange;
}

input:checked+.switch-label .switch-circle {
    transform: translateX(30px);
    /* Mueve el círculo a la derecha */
}





.mainExp375px {
    padding-left: 3%;
    padding-right: 3%;
    min-height: 90vh;
    padding-top: 8vh;
}

.espacioHeaderExpediente375 {
    margin-right: 20px;
}


.espacioHeaderExpediente480 {
    margin-right: 20px;
}









.custom-switch375 {
    display: inline-block;
    position: relative;
}

.custom-switch375 input {
    display: none;
    /* Ocultar el input checkbox */
}

.switch-label375 {
    display: flex;
    align-items: center;
    width: 50px;
    /* El ancho del switch reducido */
    height: 20px;
    /* Altura del switch reducida */
    background-color: blue;
    /* Fondo azul cuando está apagado */
    border-radius: 20px;
    /* Redondear completamente */
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.switch-label375 .switch-background375 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    background-color: blue;
    /* Fondo azul cuando está apagado */
    transition: background-color 0.3s ease;
}

.switch-label375 .switch-circle375 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    /* Más pequeño que el height del switch */
    height: 20px;
    /* Altura igual al ancho del círculo */
    background-color: white;
    border-radius: 50%;
    /* Círculo perfecto */
    position: absolute;
    top: 0px;
    /* Para centrar dentro del switch */
    left: 2px;
    /* Espacio inicial para el círculo */
    color: black;
    /* Color del texto */
    font-size: 10px;
    /* Tamaño del texto reducido */
    transition: all 0.3s ease;
}

/* Estilos cuando el switch está encendido */
input:checked+.switch-label375 .switch-background375 {
    background-color: orange;
}

input:checked+.switch-label375 .switch-circle375 {
    transform: translateX(20px);
    /* Mueve el círculo a la derecha */
}



@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap');

.subtituloTituloExp375 {
    color: #7A86A1;
    font-size: 12px;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    text-wrap: nowrap;
}

.textoSubtituloExp375 {
    color: #7A86A1;
    font-size: 12px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    margin: 0;
}

.subtituloTituloExp412 {
    color: #7A86A1;
    font-size: 12px;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    text-wrap: nowrap;
}

.textoSubtituloExp412 {
    color: #7A86A1;
    font-size: 14px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    margin: 0;
}

.subtituloTituloExp480 {
    color: #7A86A1;
    font-size: 14px;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    text-wrap: nowrap;
}

.textoSubtituloExp480 {
    color: #7A86A1;
    font-size: 16px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    margin: 0;
}


.valueEstadoTitulo375 {
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #7A86A1;
}

.valueEstadoTexto375 {
    font-family: 'Manrope', sans-serif;
    font-weight: 800;
    font-size: 14px;
    color: #7A86A1;
    margin-left: 2px;
}

.valueEstadoTitulo768 {
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #7A86A1;
}

.valueEstadoTexto768 {
    font-family: 'Manrope', sans-serif;
    font-weight: 800;
    font-size: 16px;
    color: #7A86A1;
    margin-left: 2px;
}

.valueEstadoTitulo412 {
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #7A86A1;
}

.valueEstadoTexto412 {
    font-family: 'Manrope', sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: #7A86A1;
    margin-left: 2px;
}

.valueEstadoTitulo480 {
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #7A86A1;
}

.valueEstadoTexto480 {
    font-family: 'Manrope', sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #7A86A1;
    margin-left: 2px;
}


@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap');

.textoTablaMovil {
    font-family: 'Manrope', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #7A86A1;
    line-height: 17px;
    margin: 0;
    padding: 0;
}

.inputBuscarTablaMovil {
    width: 240px;
    height: 33px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #70707080;
    border-radius: 17px;
    opacity: 1;
    font-family: 'Manrope', sans-serif;
    font-size: 12px;
    font-weight: 700;
    color: #000000;
}

.btnBuscarTablaMovil {
    width: 240px;
    height: 39px;
    background: transparent linear-gradient(83deg, #F9B035 0%, #F98C4E 53%, #F96767 100%) 0% 0% no-repeat padding-box;
    border-radius: 28px;
    opacity: 1;
    border: none;
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
}

.textoHeaderTablaMovil {
    font-family: 'Manrope', sans-serif;
    font-size: 10px;
    font-weight: 700;
    color: #8E98AF;
}