.item {
    align-items: center;
    color: #0722A8;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 30px;
    justify-content: center;
    width: 30px;
}

.disabled-page {
    color: #0722A8;
}

.active {
    border: solid 1px #0722A8;
    border-radius: 50px;
    color: #0722A8
}

.next {
    display: none;
}



.pagination-page {
    font-weight: 500;
}

.previous {
    display: none;
}

.item2 {
    align-items: center;
    color: #F98D4E;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 30px;
    justify-content: center;
    width: 30px;
}


.active2 {
    border: solid 1px #F98D4E;
    border-radius: 50px;
    color: #F98D4E
}

