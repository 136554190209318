.containerExpedienteTicket {
    margin-bottom: 15px;
    padding-top: 12px;
    padding-left: 20px;
    top: 148px;
    left: 77px;
    width: 100%;
    height: 87px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
}
#tooltip {
    background: #0722A8;
    color: white;
    font-weight: bold;
    padding: 4px 8px;
    font-size: 15px;
    border-radius: 5px;
}

.ultMod {
    text-align: left;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #0722A8;
}
.ultMod1366 {
    text-align: left;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #0722A8;
}
.hora {
    text-align: left;
    font-size: 17px;
    font-weight: 800;
    letter-spacing: 0px;
    color: #0722A8;
    opacity: 1;
    padding-top: 1.5px;
    padding-left: 10px;
}
.hora1366 {
    text-align: left;
    font-size: 11px;
    font-weight: 800;
    letter-spacing: 0px;
    color: #0722A8;
    opacity: 1;
    padding-top: 1.5px;
    padding-left: 10px;
}

.nro {
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #0722A8;
}
.nro1366 {
    text-align: left;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #0722A8;
}
.subtitle {
    text-align: left;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #0722A8;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.subtitle1366 {
    text-align: left;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #0722A8;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.texto {
    text-align: left;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #45426E;
}
.texto1366 {
    text-align: left;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #45426E;
}

.ancladoTicket {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-size: 15px;
    font-weight: 300;
    font-style: italic;
    letter-spacing: 0px;
    color: #45426E;
    opacity: 1;
}
.ancladoTicket1366 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-size: 12px;
    font-weight: 300;
    font-style: italic;
    letter-spacing: 0px;
    color: #45426E;
    opacity: 1;
}
.ancladoUserTicket {
    text-align: left;
    font-size: 15px;
    font-style: normal;
    font-weight:600;
    letter-spacing: 0px;
    color: #45426E;
}
.ancladoUserTicket1366 {
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight:600;
    letter-spacing: 0px;
    color: #45426E;
}