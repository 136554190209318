#labelDoc {
    cursor: pointer;
    color: white;
}

#cedulaA {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#cedulaB {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#registroA {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#registroB {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#presupuesto {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#ocho {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#dniA {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#dniB {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#automotor {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#fotos {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.btnDocumentacionNuevoExp {
    height: 40px;
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: 10px;
    border-radius: 20px;
    box-shadow: 3px 3px 3px #00000029;
    ;
}



@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap');

.letraPlanillaExpedienteTexto375 {
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 10px;
    color: #20242D;
}

.letraPlanillaExpedienteTexto768 {
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #20242D;
}
.recuasroTexto375 {
    width: 100%;
    height: 21px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #70707080;
    border-radius: 4px;
    opacity: 1;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 10px;
    display: flex;
    align-items: center;
    padding-left: 5px;
}
.recuasroTexto375 {
    width: 100%;
    height: 21px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #70707080;
    border-radius: 4px;
    opacity: 1;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 10px;
    display: flex;
    align-items: center;
    padding-left: 5px;
}
.recuasroTexto768 {
    width: 100%;
    height: 28px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #70707080;
    border-radius: 4px;
    opacity: 1;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding-left: 5px;
}
.recuadroGrandeTexto375 {
    width: 100%;
    height: 60px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #7070706B;
    border-radius: 8px;
    opacity: 1;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 12px;
    padding-top: 2px;
    padding-left: 5px;
    overflow-y: scroll;
}
.recuadroGrandeTexto768 {
    width: 100%;
    height: 100px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #7070706B;
    border-radius: 8px;
    opacity: 1;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding-top: 2px;
    padding-left: 5px;
    overflow-y: scroll;
}


.botonNaranja375 {
    width: 60px;
    height: 15px;
    background: #F99F41 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 18px;
    margin-right: 8px;
}
.textoBotonNaranja375 {
    font-size: 7px;
    text-decoration: none;
    font-weight: 700;
    color: #FFFFFF;
    text-wrap: nowrap;
}
.botonVerde375 {
    width: 60px;
    height: 15px;
    background: #9EFAD9 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 18px;
    margin-right: 90px;
}
.textoBotonVerde375 {
    font-size: 7px;
    text-decoration: none;
    font-weight: 700;
    color: #06A169;
    text-wrap: nowrap;
}
.botonAzul375 {
    width: 60px;
    height: 15px;
    background: #3E63EF 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 18px;
}
.textoBotonAzul375 {
    font-size: 7px;
    text-decoration: none;
    font-weight: 700;
    color: #FFFFFF;
    text-wrap: nowrap;
    border: none;
    background: none;
}