.cardEstadisticasPas11 {
    width: 471px;
    height: 339px;
    background: #F2FFEB 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
}

.cardEstadisticasPas12 {
    margin-left: 30px;
    width: 523px;
    height: 240px;
    background: #34447596 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
}

.cardEstadisticasPas13 {
    margin-left: 30px;
    width: 523px;
    height: 240px;
    background: #34757396 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
}

.cardEstadisticasComp11 {
    width: 471px;
    height: 339px;
    background: #EBFFFA 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
}

.cardEstadisticasComp12 {
    margin-left: 30px;
    width: 523px;
    height: 240px;
    background: #223089 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
}

.cardEstadisticasComp13 {
    margin-left: 30px;
    width: 523px;
    height: 240px;
    background: #0E3B55 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
}

.textoPasEstadisticasTitulo {
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-size: 25px;
    font-weight: bold;
}

.textoPasEstadisticasTitulo2 {
    text-align: left;
    letter-spacing: 0px;
    color: #7A86A1;
    opacity: 1;
    font-size: 22px;
    font-weight: 600;
}

.textoPasEstadisticasTitulo3 {
    text-align: left;
    letter-spacing: 0px;
    color: #7A86A1;
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
}

.textoPasEstadisticasTitulo4 {
    text-align: left;
    letter-spacing: -1.5px;
    color: #769AFE;
    opacity: 1;
    font-size: 60px;
    font-weight: bold;
}

.leyendaTop5PasContainer {
    width: 96px;
    height: 26px;
    background: #769AFE 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
}

.leyendaTop5Pas {
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}


.textoPasEstadisticasTitulo5 {
    text-align: left;
    letter-spacing: -0.7px;
    color: #769AFE;
    opacity: 1;
    font-size: 22px;
    font-weight: bold;
}

.textoPasEstadisticasTitulo6Container {
    width: 192px;
    height: 33px;
    background: #769AFE 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
}

.textoPasEstadisticasTitulo6 {
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
}

.textoPasEstadisticasTitulo7 {
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 22px;
    position: relative;
    top: 0px;
    font-weight: bold;
}

.textoPasEstadisticasTitulo8Container {
    width: 217px;
    height: 38px;
    background: #769AFE 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    position: relative;
    left: 80px;
}
.textoPasEstadisticasTitulo8ContainerEspeciales {
    width: 217px;
    height: 38px;
    background: #FEB576 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
}
.textoPasEstadisticasTitulo8 {
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
}

.textoPasEstadisticasTitulo9 {
    text-align: left;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
font-size: 18px;
font-style: italic;
font-weight: 400;
}

.mainEstadistica {
    padding-top: 140px;
}