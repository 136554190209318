.contenedorExpTablaMovil {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #70707080;
    border-radius: 17px;
    opacity: 1;
    height: 40px;
    display: flex;
    align-items: center;
}
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap');
.textTablaMovil {
    font-family: 'Manrope', sans-serif;
    font-size: 10px;
    font-weight: 700;
    color: #000000;
    line-height: 15px;
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
    width: 100%; 
}